.cm-s-decode.CodeMirror {
  background: #fff;
  color: rgba(0, 0, 0, 0.65);
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  padding: 4px;
  height: auto;
  overflow-y: hidden;
  overflow-x: hidden;
}
.CodeMirror-scroll {
  height: auto;
  overflow-y: hidden;
  overflow-x: hidden;
}
.cm-s-decode div.CodeMirror-selected {
  background: #e3dcce !important;
}
.cm-s-decode .CodeMirror-gutters {
  background: #faf8f5;
  border-right: 0px;
}
.cm-s-decode .CodeMirror-linenumber {
  color: #cdc4b1;
}
.cm-s-decode .CodeMirror-placeholder {
  color: #bfbfbf !important;
}

/* begin cursor */
/* .cm-s-decode .CodeMirror-cursor {
  border-left: 1px solid #93abdc;
  border-right: 0.5em solid #93abdc;
  opacity: 0.5;
}
.cm-s-decode .CodeMirror-activeline-background {
  background: #e3dcce;
  opacity: 0.5;
} */
/* .cm-s-decode .cm-fat-cursor .CodeMirror-cursor {
  background: #93abdc;
  opacity: 0.5;
} */
/* end cursor */

.cm-s-decode span.cm-variable {
  color: #030852;
  background-color: #d6e4ff;
  padding: 2px;
  border-radius: 6px;
}

.cm-s-decode span.cm-variable-2 {
  color: #092b00;
  background-color: #f6ffed;
  padding: 2px;
  border-radius: 6px;
}

.CodeMirror.cm-s-has-left-addon {
  border-bottom-left-radius: 0px;
  border-top-left-radius: 0px;
}

.CodeMirror.cm-s-single-line {
  overflow-y: unset;
  height: auto;
  overflow-wrap: break-word;
  word-wrap: break-all;
}

.cm-s-decode span.cm-atom,
.cm-s-decode span.cm-number,
.cm-s-decode span.cm-keyword,
.cm-s-decode span.cm-attribute,
.cm-s-decode span.cm-quote,
.cm-s-decode-light span.cm-hr,
.cm-s-decode-light span.cm-link {
  color: #063289;
}

/* .cm-s-decode span.cm-property {
  color: #b29762;
}
.cm-s-decode span.cm-punctuation,
.cm-s-decode span.cm-unit,
.cm-s-decode span.cm-negative {
  color: #063289;
}
.cm-s-decode span.cm-string,
.cm-s-decode span.cm-operator {
  color: #1659df;
}
.cm-s-decode span.cm-positive {
  color: #896724;
}

.cm-s-decode span.cm-variable-2,
.cm-s-decode span.cm-variable-3,
.cm-s-decode span.cm-type,
.cm-s-decode span.cm-string-2,
.cm-s-decode span.cm-url {
  color: #896724;
}
.cm-s-decode span.cm-def,
.cm-s-decode span.cm-tag,
.cm-s-decode span.cm-builtin,
.cm-s-decode span.cm-qualifier,
.cm-s-decode span.cm-header,
.cm-s-decode span.cm-em {
  color: #2d2006;
}
.cm-s-decode span.cm-bracket,
.cm-s-decode span.cm-comment {
  color: #b6ad9a;
}
*/

/* using #f00 red for errors, don't think any of the colorscheme variables will stand out enough, ... maybe by giving it a background-color ... */
/* .cm-s-decode span.cm-error { background: #896724; color: #728fcb; } */
/* .cm-s-decode span.cm-error, .cm-s-decode span.cm-invalidchar { color: #f00; } */

/* .cm-s-decode span.cm-header {
  font-weight: normal;
}  */
.cm-s-decode .CodeMirror-matchingbracket {
  text-decoration: underline;
  color: #faf8f5 !important;
}


/*
  Codemirror default theme.

  Used to style the JSON input.
*/

.cm-s-default.CodeMirror {
  background: #fff;
  color: rgba(0, 0, 0, 0.65);
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  padding: 4px;
  height: auto;
  overflow-y: hidden;
  overflow-x: hidden;
}

.cm-s-default div.CodeMirror-selected {
  background: #e3dcce !important;
}

.cm-s-default .CodeMirror-gutters {
  background: #faf8f5;
  border-right: 0px;
}

.cm-s-default .CodeMirror-linenumber {
  color: #cdc4b1;
}

.cm-s-default .CodeMirror-placeholder {
  color: #bfbfbf !important;
}

.cm-s-default span.cm-variable {
  color: #030852;
  background-color: #d6e4ff;
  padding: 2px;
  border-radius: 6px;
}

.cm-s-default span.cm-variable-2 {
  color: #092b00;
  background-color: #f6ffed;
  padding: 2px;
  border-radius: 6px;
}