.ant-layout-header {
  line-height: 50px;
}

.ant-menu.ant-menu-dark .ant-menu-item-selected {
  background-color: #c6c7c4;
  color: #2e4052;
}

.ant-menu-dark .ant-menu-item-selected .anticon {
  color: #2e4052;
}

/* make sure the ant-space takes all of width */
.ant-space.ant-space-vertical {
  width: 100%;
}