.cm-s-default-ro.CodeMirror {
  background: #f0f5ff70;
  border-radius: 5px;
  border: 1px solid #e1e3e5;
  padding: 0px 6px;
  height: auto;
  overflow-y: hidden;
  overflow-x: auto;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
  width: fit-content;
}

.cm-s-default-ro.CodeMirror-scroll {
  height: auto;
  overflow-y: hidden;
  overflow-x: hidden;
}

.cm-s-default-ro .cm-header {
  color: blue;
}
.cm-s-default-ro .cm-quote {
  color: #090;
}
.cm-negative {
  color: #d44;
}
.cm-positive {
  color: #292;
}
.cm-header,
.cm-strong {
  font-weight: bold;
}
.cm-em {
  font-style: italic;
}
.cm-link {
  text-decoration: underline;
}
.cm-strikethrough {
  text-decoration: line-through;
}

.cm-s-default-ro .cm-keyword {
  color: #708;
}
.cm-s-default-ro .cm-atom {
  color: #219;
}
.cm-s-default-ro .cm-number {
  color: #164;
}
.cm-s-default-ro .cm-def {
  color: #00f;
}
.cm-s-default-ro .cm-variable,
.cm-s-default-ro .cm-punctuation,
.cm-s-default-ro .cm-property,
.cm-s-default-ro .cm-operator {
}
.cm-s-default-ro .cm-variable-2 {
  color: #05a;
}
.cm-s-default-ro .cm-variable-3,
.cm-s-default-ro .cm-type {
  color: #085;
}
.cm-s-default-ro .cm-comment {
  color: #a50;
}
.cm-s-default-ro .cm-string {
  color: #a11;
}
.cm-s-default-ro .cm-string-2 {
  color: #f50;
}
.cm-s-default-ro .cm-meta {
  color: #555;
}
.cm-s-default-ro .cm-qualifier {
  color: #555;
}
.cm-s-default-ro .cm-builtin {
  color: #30a;
}
.cm-s-default-ro .cm-bracket {
  color: #997;
}
.cm-s-default-ro .cm-tag {
  color: #170;
}
.cm-s-default-ro .cm-attribute {
  color: #00c;
}
.cm-s-default-ro .cm-hr {
  color: #999;
}
.cm-s-default-ro .cm-link {
  color: #00c;
}

.cm-s-default-ro .cm-error {
  color: #f00;
}
.cm-invalidchar {
  color: #f00;
}
